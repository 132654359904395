<template>
   <div align="center">
        <br>
        <div align="center">
            <span class="text-h4">Dati per emissione fattura</span>
            <br>
            Pratica numero {{guid_pratica}}
            <hr>
        </div>
        <br><br>
        <div class="row justify-center q-pl-md">

            <div class="col-12 col-md-6 q-pr-md" align="left">

                <h5 class="q-mt-sm q-mb-sm">Dati di Fatturazione</h5>
                <br>
                <!-- 03/07/2023 - Come suggerito da Michele, metto l'elenco dei singoli contratti con il loro
                     prezzo senza consulenza, diritti o altro e in fondo la somma di consulenza e diritti
                     su un unica voce. La stessa cosa va fatta quando si stampano le fatture.
                     17/07/2023 - Come richiesto da Claudio, modifico la lista per visualizzare il segnaposto
                     per ogni singolo contratto invece che un unico segnaposto per fattura.
                     //-->
            <!--    <table class="table table-bordered" style="font-size: 18px; width: 100%;">
                    <thead>
                        <tr>
                            <th align="left">Garanzia concessa</th>
                            <th>Importo</th>
                        </tr>
                    </thead>
                    <tbody v-for="(prodotto,index) in elenco_contratti.contratti"
                              v-bind:key="index">
                        <tr>
                            <td>{{ prodotto.label_tariffa }}</td>
                            <td align="right">{{ prodotto.premio.toFixed(2) }} &euro;</td>
                        </tr>
                    </tbody>
                    <tbody>
                        <tr>
                            <td>Consulenza per intermediazione assicurativa</td>
                            <td align="right">{{ getConsulenzaTotale() }} &euro;</td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <td align="right">TOTALE:</td>
                            <td align="right">{{ getImportoTotale() }} &euro;</td>
                        </tr>
                    </tfoot>
                </table> //-->

                <div  v-for="(prodotto,index) in elenco_contratti.contratti"
                        v-bind:key="index">

                    <table class="table table-bordered" style="font-size: 18px; width: 100%;">
                        <thead>
                            <tr>
                                <th align="left">Garanzia concessa</th>
                                <th>Importo</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{{prodotto.label_prodotto}} - {{ prodotto.label_tariffa }}</td>
                                <td align="right">{{ prodotto.premio.toFixed(2) }} &euro;</td>
                            </tr>
                            <tr>
                                <td>Consulenza per intermediazione assicurativa</td>
                                <td align="right">{{ getConsulenza(prodotto.tariffa) }} &euro;</td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <tr>
                                <td align="right"><strong>TOTALE:</strong></td>
                                <td align="right"><strong>{{ getImportoTotale(prodotto.tariffa) }}</strong> &euro;</td>
                            </tr>
                        </tfoot>
                    </table>
                    <br>
                </div>
            </div>

            <div class="col-12 col-md-6 q-pr-md" align="left">
                <h5 class="q-mt-sm q-mb-sm">Dati del Contraente</h5>
                <table class="table table-bordered" style="font-size: 18px; width: 100%;">
                    <tbody>
                        <tr>
                            <td>Nominativo</td>
                            <td>{{ dati_cliente.cognome }} {{ dati_cliente.nome }}</td>
                        </tr>
                        <tr>
                            <td>Tipo persona</td>
                            <td>{{ dati_cliente.tipo_persona }}</td>
                        </tr>
                        <tr v-if="dati_cliente.tipo_cliente === 'persona_fisica'">
                            <td>Sesso</td>
                            <td>{{ dati_cliente.sesso }}&nbsp;</td>
                        </tr>
                        <tr v-if="dati_cliente.tipo_cliente === 'persona_fisica'">
                            <td>Codice Fiscale</td>
                            <td>{{ dati_cliente.codice_fiscale }}&nbsp;</td>
                        </tr>
                        <tr v-if="dati_cliente.tipo_cliente !== 'persona_fisica'">
                            <td>Partita IVA</td>
                            <td>{{ dati_cliente.partita_iva }}&nbsp;</td>
                        </tr>
                        <tr>
                            <td style="width: 50%;">
                                Soggetto escluso dall’obbligatorietà della comunicazione PEC (quali imprese individuali, consorzi con attività
                                esterna di cui all’art. 2612 c.c., imprese che non sono costituite in forma societaria come Associazioni/Fondazioni/Enti).
                                Inserire '000000' nel codice univoco destinatario
                            </td>
                            <td>
                                <q-option-group
                                    v-model="dati_cliente.esente_obbligo_pec"
                                    :options="options"
                                />
                            </td>
                        </tr>
                        <tr v-if="dati_cliente.tipo_cliente !== 'persona_fisica'">
                            <td>Codice Univoco Destinatario</td>
                            <td>
                                <q-input
                                    dense
                                    outlined
                                    v-model="dati_cliente.codice_univoco_destinatario"
                                    label="Codice Univoco Destinatario"
                                    :rules="[ val => val.length === 7 || 'Il Codice Univoco Destinatario deve essere lungo 7 caratteri' ]"
                                />
                            </td>
                        </tr>
                        <template v-if="dati_cliente.tipo_cliente === 'persona_fisica'">
                            <tr v-if="dati_cliente.tipo_cliente !== 'persona_fisica'">
                                <td>Data di nascita</td>
                                <td>{{ dati_cliente.data_nascita }}&nbsp;</td>
                            </tr>
                            <tr v-if="dati_cliente.tipo_cliente !== 'persona_fisica'">
                                <td>Comune di nascita</td>
                                <td>{{ dati_cliente.comune_nascita }}&nbsp;</td>
                            </tr>
                            <tr v-if="dati_cliente.tipo_cliente !== 'persona_fisica'">
                                <td>Provincia di nascita</td>
                                <td>{{ dati_cliente.provincia_nascita }}&nbsp;</td>
                            </tr>
                        </template>
                        <tr>
                            <td>Professione</td>
                            <td>{{ dati_cliente.professione }}&nbsp;</td>
                        </tr>
                        <tr>
                            <td>Indirizzo di residenza/Sede legale</td>
                            <td>{{ dati_cliente.indirizzo_residenza }}&nbsp;</td>
                        </tr>
                        <tr>
                            <td>Comune di residenza/Sede legale</td>
                            <td>{{ dati_cliente.comune_residenza }}&nbsp;</td>
                        </tr>
                        <tr>
                            <td>CAP di residenza/Sede legale</td>
                            <td>{{ dati_cliente.cap_residenza }}&nbsp;</td>
                        </tr>
                        <tr>
                            <td>Provincia di residenza/Sede legale</td>
                            <td>{{ dati_cliente.provincia_residenza }}&nbsp;</td>
                        </tr>

                        <tr>
                            <td>Indirizzo recapito</td>
                            <td>{{ dati_cliente.indirizzo_recapito }}&nbsp;</td>
                        </tr>
                        <tr>
                            <td>Comune recapito</td>
                            <td>{{ dati_cliente.comune_recapito }}&nbsp;</td>
                        </tr>
                        <tr>
                            <td>CAP recapito</td>
                            <td>{{ dati_cliente.cap_recapito }}&nbsp;</td>
                        </tr>
                        <tr>
                            <td>Provincia recapito</td>
                            <td>{{ dati_cliente.provincia_recapito }}&nbsp;</td>
                        </tr>
                        <tr>
                            <td>Telefono</td>
                            <td>{{ dati_cliente.telefono }}&nbsp;</td>
                        </tr>
                        <tr>
                            <td>Cellulare</td>
                            <td>{{ dati_cliente.cellulare }}&nbsp;</td>
                        </tr>
                        <tr>
                            <td>Email</td>
                            <td>{{ dati_cliente.email }}&nbsp;</td>
                        </tr>
                        <tr>
                            <td>Email PEC</td>
                            <td>
                                <q-input
                                    dense
                                    outlined
                                    v-model="dati_cliente.pec"
                                    label="Email PEC"
                                    :rules="[ val => val.length > 0 || 'Indirizzo di posta elettronica PEC obbligatorio' ]"
                                />
                            </td>
                        </tr>

                    </tbody>
                </table>
            </div>
            <div class="col-12 col-md-12"><hr></div>

            <div class="col-md-12 col-12 q-pr-md row justify-center" align="center">

                    <QQButton label="INDIETRO"
                        color="blue-grey"
                        icon="undo"
                        size="md"
                        icon_align="left"
                        @click.native="onIndietroClicked()"
                    />

                    <QQButton label="Registra"
                        color="blue-grey"
                        icon="mdi-arrow-right-bold"
                        size="md"
                        icon_align="right"
                        @click.native="onAvantiClicked()"
                    />

            </div>
        </div>
        <br><br><br><br><br>
    </div>
</template>

<script>

import { mapState } from 'vuex';
import { mapFields } from 'vuex-map-fields'
import { mapActions } from "vuex";
import commonLib from '@/libs/commonLib';
import QQButton from "@/components/QQButton.vue";

export default {
    name: "DatiClientePerFattura",
    components: {
        QQButton
    },
    data() {
        return {
            elenco_contratti: [],
            dati_economici: [],
            dati_cliente: [],
            options: [
                { label: 'Si', value: 1 },
                { label: 'No', value: 0 }
            ]
        }
    },
    computed: {
        ...mapState({
            is_loading: state => state.gestionePratiche.is_loading
        }),
        ...mapFields('gestioneFatture', {
            is_loading: "is_loading",
            guid_pratica: "guid_pratica"
        }),
    },
    methods: {
        ...mapActions({
            fetchElencoContrattiPratica: "gestionePratiche/fetchElencoContrattiPratica",
            fetchDatiEconomiciBoxContrattuale: "gestionePratiche/fetchDatiEconomiciBoxContrattuale",
            fetchDatiClientePratica: "gestionePratiche/fetchDatiClientePratica",
            RegistraDatiFattura: "gestioneFatture/RegistraDatiFattura"
        }),
        getConsulenza(tariffa) {
            var dati_economici_tariffa = this.dati_economici.filter(d => {
                return (d.tariffa === tariffa);
            });

            var premio = 0.00;
            var dato = dati_economici_tariffa.filter(d => {
                return ((d.tipo === "DIRITTI_ABY") ||
                        (d.tipo === "COSTO_SERVIZIO")  ||
                        (d.tipo === "CONSULENZA") ||
                        (d.tipo === "DIRITTI_FORNITORE") ||
                        (d.tipo === "DIRITTI_TERZI")
                    );
            });

            if (!commonLib.isEmpty(dato)) {
                for (var i=0;i<dato.length;i++) {
                    premio += parseFloat(dato[i].importo);
                }
            }

            return parseFloat(premio).toFixed(2);
        },
        getConsulenzaTotale() {
            var premio = 0.00;
            var dato = this.dati_economici.filter(d => {
                return ((d.tipo === "DIRITTI_ABY") ||
                        (d.tipo === "COSTO_SERVIZIO")  ||
                        (d.tipo === "CONSULENZA") ||
                        (d.tipo === "DIRITTI_FORNITORE") ||
                        (d.tipo === "DIRITTI_TERZI")
                    );
            });

            if (!commonLib.isEmpty(dato)) {
                for (var i=0;i<dato.length;i++) {
                    premio += parseFloat(dato[i].importo);
                }
            }

            return parseFloat(premio).toFixed(2);
        },
        getImportoTotale(tariffa) {
            var dati_economici_tariffa = this.dati_economici.filter(d => {
                return (d.tariffa === tariffa);
            });

            var premio = 0.00;

            var dato = dati_economici_tariffa.filter(d => {
                return ((d.tipo === "DIRITTI_ABY") ||
                        (d.tipo === "COSTO_SERVIZIO")  ||
                        (d.tipo === "CONSULENZA") ||
                        (d.tipo === "DIRITTI_FORNITORE") ||
                        (d.tipo === "DIRITTI_TERZI") ||
                        (d.tipo === "PREMIO_LORDO_POLIZZA")
                    );
            });

            if (!commonLib.isEmpty(dato)) {
                for (var i=0;i<dato.length;i++) {
                    premio += parseFloat(dato[i].importo);
                }
            }

            return parseFloat(premio).toFixed(2);
        },
        onIndietroClicked() {
            this.$router.push({name : "Pratiche.DefinisciPratica"});
        },
        async onAvantiClicked() {
            // Verifica che i campi modificabili siano compilati e non vuoti
            if ((this.dati_cliente.esente_obbligo_pec === "") ||
                (this.dati_cliente.codice_univoco_destinatario === "") ||
                (this.dati_cliente.pec === "")) {

                this.$q.dialog({
                    title: 'Attenzione',
                    message: 'Il campo Codice Univoco Destinatario e/o PEC devono essere compilati.',
                });

                return;
            }


            this.$q.dialog({
                cancel: {
                    focus: true,
                    flat: false,
                    label: "No",
                    color: "primary"
                },
                ok: {
                    color: "secondary",
                    flat: false,
                    label: "Si, procedi"
                },
                title: 'Attenzione',
                message: 'procedo con la richiesta di emissione della fattura?',
            }).onOk(async () => {
                this.is_loading = false;
                await this.RegistraDatiFattura(
                    {
                        guidPratica: this.guid_pratica,
                        Pec: this.dati_cliente.pec,
                        CodiceUnivocoDestinazione: this.dati_cliente.codice_univoco_destinatario,
                        EsentePec: this.dati_cliente.esente_obbligo_pec
                    }
                );
                this.is_loading = true;

                this.$router.push({name : "Pratiche.MenuGestionePratiche"});
            });
        }
    },
    async mounted() {
        this.is_loading = true;

        console.log("guid_pratica:",this.guid_pratica);

        this.elenco_contratti = await this.fetchElencoContrattiPratica(this.guid_pratica);
        this.dati_economici   = await this.fetchDatiEconomiciBoxContrattuale(this.guid_pratica);
        this.dati_cliente     = await this.fetchDatiClientePratica(this.guid_pratica);

        // Se il codice univoco destinatario e` null allora lo forzo a 7 zeri
        if (commonLib.isEmpty(this.dati_cliente.codice_univoco_destinatario)) this.dati_cliente.codice_univoco_destinatario = "0000000";


        console.log("elenco contratti:",this.elenco_contratti);
        console.log("dati economici:",this.dati_economici);
        console.log("dati cliente:",this.dati_cliente);
    }
}
</script>

<style scoped>
    td.domanda {
        text-align: right;
        font-style: italic;
        font-weight: bold;
    }
    td.risposta {
        border-bottom: 1px SOLID #c0c0c0;
        padding: 6px;
        width: 60%;
        vertical-align: bottom;
    }
    .table {
        width: 100%;
    }
    .table-bordered {
        border-collapse: collapse;
        width: 100%;

    }

    .table-bordered td, .table th {
        border: 1px solid #c0c0c0;
        padding: 8px;
        font-size: 0.8em;
    }

    .table-bordered th {
        padding-top: 12px;
        padding-bottom: 12px;
        padding-left: 8px;
        text-align: left;
        color: #404040;
        background-color: #F0F0F0;
        border: 1px SOLID #404040;

    }
</style>
